import {
    UPDATE_ADMINS_LIST,
    TOGGLE_SHOW_SAVE,
    TOGGLE_SAVE_LOADING,
    UPDATE_EMPLOYEES_LIST,
    UPDATE_ADP_LIST,
    UPDATE_EMPLOYEE,
    UPDATE_ADP_EMPLOYEE,
    SELECT_ADP_EMPLOYEE,
    SELECT_ALL_EMPLOYEES,
    DELETE_MULTIPLE,
    RESET_EMPLOYEE,
    UPDATE_INVITE_EMPLOYEE,
    UPDATE_TABLE_DATA,
    UPDATE_EMPLOYEES_COUNT,
    UPDATE_EMPLOYEES_PAGE_COUNT,
    UPDATE_GOTAG_LIST,
    UPDATE_EMPLOYEES_SORT_LIST,
    UPDATE_EMPLOYEES_SORT_DETAILS,
    UPDATE_SAVED_ADMINS_LIST,
    // UPDATE_CONFRIM_ADMINS_LIST,
} from '../actions/types';

const initialState = {
    tableData: [],
    gotagFullList: [],
    list: [],
    adpList: [],
    admins: [],
    tempSavedAdmins: [],
    confrimAdmins: [],
    employeesCount: 0,
    employeesPageCount: 0,
    inviteEmployees: [],
    sortedList: [],
    sortDetails: {
        isSorted: false,
        isSortedDesc: false,
        sortField: '',
        page: 0,
    },
    saveButton: {
        show: false,
        loading: false,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ADMINS_LIST: {
            return {
                ...state,
                admins: action.payload,
            };
        }
        case TOGGLE_SHOW_SAVE: {
            return {
                ...state,
                saveButton: {
                    ...state.saveButton,
                    show: action.payload,
                },
            };
        }
        case TOGGLE_SAVE_LOADING: {
            return {
                ...state,
                saveButton: {
                    ...state.saveButton,
                    loading: action.payload,
                    show: action.payload,
                },
            };
        }
        case UPDATE_EMPLOYEES_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case UPDATE_TABLE_DATA: {
            return {
                ...state,
                tableData: action.payload,
            };
        }
        case UPDATE_ADP_LIST: {
            return {
                ...state,
                adpList: action.payload,
            };
        }
        case UPDATE_EMPLOYEE: {
            const employees = state.list.map((employee) => {
                if (employee.email === action.payload.email) {
                    return action.payload;
                }
                return employee;
            });
            return {
                ...state,
                list: employees,
            };
        }
        case UPDATE_ADP_EMPLOYEE: {
            const employees = state.adpList.map((employee) => {
                if (employee.email === action.payload.email) {
                    return action.payload;
                }
                return employee;
            });
            return {
                ...state,
                adpList: employees,
            };
        }
        case SELECT_ADP_EMPLOYEE: {
            const updatedEmpList = state.adpList.map((employee) => {
                if (employee.email === action.payload.email) {
                    const newEmployee = {...employee};
                    newEmployee.isSelected = action.payload.isSelected;
                    return newEmployee;
                }
                return employee;
            });
            return {
                ...state,
                adpList: updatedEmpList,
            };
        }
        case SELECT_ALL_EMPLOYEES: {
            const updatedEmpList = state.adpList.map((employee) => {
                const newEmployee = {...employee};
                newEmployee.isSelected = action.payload;
                return newEmployee;
            });
            return {
                ...state,
                adpList: updatedEmpList,
            };
        }

        case DELETE_MULTIPLE: {
            const updatedEmpList = state.adpList.map((employee) => {
                const newEmployee = {...employee};
                delete newEmployee._id;
                return newEmployee;
            });
            return {...state, adpList: updatedEmpList};
        }

        case RESET_EMPLOYEE: {
            const updatedEmpList = state.list.map((employee) => {
                if (employee._id === action.payload._id) {
                    return action.payload;
                }
                return employee;
            });
            return {...state, list: updatedEmpList};
        }
        case UPDATE_INVITE_EMPLOYEE: {
            return {
                ...state,
                inviteEmployees: action.payload,
            };
        }
        case UPDATE_EMPLOYEES_COUNT: {
            return {
                ...state,
                employeesCount: action.payload,
            };
        }

        case UPDATE_GOTAG_LIST: {
            return {
                ...state,
                gotagFullList: action.payload,
            };
        }
        case UPDATE_EMPLOYEES_PAGE_COUNT: {
            return {
                ...state,
                employeesPageCount: action.payload,
            };
        }
        case UPDATE_EMPLOYEES_SORT_DETAILS: {
            return {
                ...state,
                sortDetails: action.payload,
            };
        }

        case UPDATE_EMPLOYEES_SORT_LIST: {
            return {
                ...state,
                sortedList: action.payload,
            };
        }

        case UPDATE_SAVED_ADMINS_LIST: {
            return {
                ...state,
                tempSavedAdmins: action.payload,
            };
        }

        // case UPDATE_CONFRIM_ADMINS_LIST: {
        //     return {
        //         ...state,
        //         confrimAdmins: action.payload,
        //     };
        // }

        default:
            return state;
    }
}
