// TOAST MESSAGE
export const UPDATE_TOAST = 'UPDATE_TOAST';

// EMPLOYEE RELATED
export const UPDATE_ADMINS_LIST = 'UPDATE_ADMINS_LIST';
export const TOGGLE_SAVE_LOADING = 'TOGGLE_SAVE_LOADING';
export const TOGGLE_SHOW_SAVE = 'TOGGLE_SHOW_SAVE';
export const UPDATE_EMPLOYEES_LIST = 'UPDATE_EMPLOYEES_LIST';
export const UPDATE_EMPLOYEES_COUNT = 'UPDATE_EMPLOYEES_COUNT';
export const UPDATE_EMPLOYEES_PAGE_COUNT = 'UPDATE_EMPLOYEES_PAGE_COUNT';
export const UPDATE_EMPLOYEES_SORT_DETAILS = 'UPDATE_EMPLOYEES_SORT_DETAILS';
export const UPDATE_EMPLOYEES_SORT_LIST = 'UPDATE_EMPLOYEES_SORT_LIST';
export const UPDATE_ADP_LIST = 'UPDATE_ADP_LIST';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_ADP_EMPLOYEE = 'UPDATE_ADP_EMPLOYEE';
export const SELECT_ADP_EMPLOYEE = 'SELECT_ADP_EMPLOYEE';
export const UPDATE_GOTAG_LIST = 'UPDATE_GOTAG_LIST';
export const SELECT_ALL_EMPLOYEES = 'SELECT_ALL_EMPLOYEES';
export const DELETE_MULTIPLE = 'DELETE_MULTIPLE';
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';
export const UPDATE_INVITE_EMPLOYEE = 'UPDATE_INVITE_EMPLOYEE';
export const UPDATE_TABLE_DATA = 'UPDATE_TABLE_DATA';
export const UPDATE_SAVED_ADMINS_LIST = 'UPDATE_SAVED_ADMINS_LIST';
// export const UPDATE_CONFRIM_ADMINS_LIST = 'UPDATE_CONFRIM_ADMINS_LIST';

// SUPPORT RELATED
export const SUPPORT_GET_LIST = 'SUPPORT_GET_LIST';
export const SUPPORT_DELETE_ID = 'SUPPORT_DELETE_ID';
export const SUPPORT_ADD_NEW = 'SUPPORT_ADD_NEW';
export const SUPPORT_UPDATE_ID = 'SUPPORT_UPDATE_ID';

// POLICY RELATED
export const POLICY_GET_LIST = 'POLICY_GET_LIST';
export const POLICY_DELETE_ID = 'POLICY_DELETE_ID';
export const POLICY_ADD_NEW = 'POLICY_ADD_NEW';
export const POLICY_UPDATE_ID = 'POLICY_UPDATE_ID';

// SETUP RELATED
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_ACCESS_STATUS = 'UPDATE_ACCESS_STATUS';
export const UPDATE_SETUP_INDEX = 'UPDATE_SETUP_INDEX';
export const UPDATE_SETUP_TAB = 'UPDATE_SETUP_TAB';
export const UPDATE_SETUP_DONE = 'UPDATE_SETUP_DONE';
export const UPDATE_SETUP_DATA = 'UPDATE_SETUP_DATA';
export const CLEAR_SETUP_DATA = 'CLEAR_SETUP_DATA';

// EMERGENCY RELATED
export const EMERGENCY_GET_LIST = 'EMERGENCY_GET_LIST';
export const EMERGENCY_DELETE_ID = 'EMERGENCY_DELETE_ID';
export const EMERGENCY_ADD_NEW = 'EMERGENCY_ADD_NEW';
export const EMERGENCY_UPDATE_ID = 'EMERGENCY_UPDATE_ID';

// ASSETS RELATED
export const ASSETS_GET_ASSIGNED_LIST = 'ASSETS_GET_ASSIGNED_LIST';
export const ASSETS_GET_UNASSIGNED_LIST = 'ASSETS_GET_UNASSIGNED_LIST';
export const ASSETS_GET_SORT_DETAILS = 'ASSETS_GET_SORT_DETAILS';
export const ASSETS_GET_SORT_LIST = 'ASSETS_GET_SORT_LIST';
export const ASSETS_DELETE_ID = 'ASSETS_DELETE_ID';
export const ASSETS_ADD_NEW = 'ASSETS_ADD_NEW';
export const ASSETS_UPDATE_ID = 'ASSETS_UPDATE_ID';
export const ASSETS_ADD_MULTIPLE = 'ASSETS_ADD_MULTIPLE';
export const ASSETS_ID_RETURN = 'ASSETS_ID_RETURN';
export const ASSETS_GET_ASSIGNED_COUNT = 'ASSETS_GET_ASSIGNED_COUNT';
export const ASSETS_GET_UNASSIGNED_COUNT = 'ASSETS_GET_UNASSIGNED_COUNT';
export const ASSETS_GET_UNASSIGNED_PAGE_COUNT =
    'ASSETS_GET_UNASSIGNED_PAGE_COUNT';

// POLLS RELATED
export const POLLS_GET_ALL_LIST = 'POLLS_GET_ALL_LIST';
export const POLLS_GET_EXPIRED_LIST = 'POLLS_GET_EXPIRED_LIST';
export const POLLS_GET_UPCOMING_LIST = 'POLLS_GET_UPCOMING_LIST';
export const POLLS_GET_ACTIVE_LIST = 'POLLS_GET_ACTIVE_LIST';
export const POLLS_DELETE_ID = 'POLLS_DELETE_ID';
export const POLLS_ADD_NEW = 'POLLS_ADD_NEW';
export const POLLS_UPDATE_ID = 'POLLS_UPDATE_ID';
export const POLLS_ERROR = 'POLLS_ERROR';
export const POLLS_ALL_SHOW_MORE = 'POLLS_ALL_SHOW_MORE';
export const POLLS_EXPIRED_SHOW_MORE = 'POLLS_EXPIRED_SHOW_MORE';
export const POLLS_UPCOMING_SHOW_MORE = 'POLLS_UPCOMING_SHOW_MORE';
export const POLLS_ACTIVE_SHOW_MORE = 'POLLS_ACTIVE_SHOW_MORE';

// ASSETS RELATED
export const ANNOUNCEMENTS_GET_LIST = 'ANNOUNCEMENTS_GET_LIST';
export const ANNOUNCEMENTS_DELETE_ID = 'ANNOUNCEMENTS_DELETE_ID';
export const ANNOUNCEMENTS_ADD_NEW = 'ANNOUNCEMENTS_ADD_NEW';
export const ANNOUNCEMENTS_UPDATE_ID = 'ANNOUNCEMENTS_UPDATE_ID';
export const ANNOUNCEMENTS_SHOW_MORE = 'ANNOUNCEMENTS_SHOW_MORE';

// FEEDBACK RELATED
export const FEEDBACK_GET_LIST = 'FEEDBACK_GET_LIST';
// HOLIDAYS RELATED
export const HOLIDAYS_GET_LIST = 'HOLIDAYS_GET_LIST';
export const ALL_HOLIDAYS_SET_LIST = 'ALL_HOLIDAYS_SET_LIST';
export const UPDATE_SELECTED_HOLIDAYS_LIST = 'UPDATE_SELECTED_HOLIDAYS_LIST';

// EXPENSE RELATED
export const GET_EXPENSES_LIST = 'GET_EXPENSES_LIST';
export const GET_FILTERED_LIST = 'GET_FILTERED_LIST';
export const MODIFY_EXPENSE = 'MODIFY_EXPENSE';
export const GET_FILTERED_MONTH = 'GET_FILTERED_MONTH';
export const GET_FILTERED_STATUS = 'GET_FILTERED_STATUS';

// EVENT RELATED
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const SET_ALL_LIST = 'SET_ALL_LIST';
export const SET_ALL_COUNT = 'SET_ALL_COUNT';
export const SET_ALL_PAGE_COUNT = 'SET_ALL_PAGE_COUNT';

export const SET_ONGOING_LIST = 'SET_ONGOING_LIST';
export const SET_ONGOING_COUNT = 'SET_ONGOING_COUNT';
export const SET_ONGOING_PAGE_COUNT = 'SET_ONGOING_PAGE_COUNT';

export const SET_UPCOMING_LIST = 'SET_UPCOMING_LIST';
export const SET_UPCOMING_COUNT = 'SET_UPCOMING_COUNT';
export const SET_UPCOMING_PAGE_COUNT = 'SET_UPCOMING_PAGE_COUNT';

export const SET_SORT_LIST = 'SET_SORT_LIST';
export const SET_SORT_DETAILS = 'SET_SORT_DETAILS';

export const CREATE_EVENT = 'CREATE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

// NOTIFICATION RELATED
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';

// DASHBOARD RELATED
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';

// GOALS RELATED
export const CREATE_GOAL = 'CREATE GOAL';
export const GET_GOAL_DATA = 'GET_GOAL_DATA';
export const GET_GOAL_SORTED = 'GET_GOAL_SORTED';
export const GET_GOAL_PIC_LIST = 'GET_GOAL_PIC_LIST';
export const GET_GOAL_EMPLOYEE_LIST = 'GET_GOAL_EMPLOYEE_LIST';
export const GET_GOAL_EMPLOYEE_LOAD = 'GET_GOAL_EMPLOYEE_LOAD';
export const GET_FIRST_GOAL_EMPLOYEE = 'GET_FIRST_GOAL_EMPLOYEE';

// THUMBNAIL RELATED
export const CREATE_THUMBNAIL_LIST = 'CREATE_THUMBNAIL_LIST';
