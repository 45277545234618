import {GET_NOTIFICATION_LIST, GET_NOTIFICATION_COUNT} from '../actions/types';

const initialState = {
    list: [],
    showMoreCount: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATION_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case GET_NOTIFICATION_COUNT: {
            return {
                ...state,
                showMoreCount: action.payload,
            };
        }

        default:
            return state;
    }
}
