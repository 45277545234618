import errorIcon from '../../assets/error-new.svg';
import './ErrorPage.scss';

// eslint-disable-next-line arrow-body-style
const ErrorPage = () => {
    return (
        <div className="error-cont">
            <img alt="Error illustration" src={errorIcon} />
            <div className="text-wrapper">
                <div className="something-text">Ohh! Something went wrong</div>
                <div className="brace-text">
                    Brace yourself till we fix the error.
                </div>
                <div className="brace-text">
                    You may also refresh the page or try again.
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
