import {
    GET_GOAL_DATA,
    GET_GOAL_SORTED,
    GET_GOAL_EMPLOYEE_LIST,
    GET_GOAL_EMPLOYEE_LOAD,
    GET_GOAL_PIC_LIST,
    GET_FIRST_GOAL_EMPLOYEE,
} from '../actions/types';

const initialState = {
    list: [],
    picList: [],
    picLoading: true,
    employeeList: {},
    firstEmployeeList: {},
    employeeLoading: true,
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_GOAL_DATA: {
            return {
                ...state,
                list: action.payload,
                loading: false,
            };
        }
        case GET_GOAL_SORTED: {
            return {
                ...state,
                list: action.payload,
                loading: false,
            };
        }
        case GET_GOAL_PIC_LIST: {
            return {
                ...state,
                picList: action.payload,
                picLoading: false,
            };
        }
        case GET_GOAL_EMPLOYEE_LIST: {
            return {
                ...state,
                employeeList: action.payload,
            };
        }
        case GET_GOAL_EMPLOYEE_LOAD: {
            return {
                ...state,
                employeeLoading: action.payload,
            };
        }
        case GET_FIRST_GOAL_EMPLOYEE: {
            return {
                ...state,
                firstEmployeeList: action.payload,
            };
        }
        default:
            return state;
    }
}
