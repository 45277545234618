import {GET_DASHBOARD_DATA} from '../actions/types';

const initialState = {
    goalsCount: {},
    pollsCount: {},
    eventsCount: {},
    employeesCount: null,
    expensesCount: {},
    profileCompletion: 0,
    upcomingEvent: {},
    ongoingEvent: {},
    upcomingHolidays: [],
    expenses: [],
    recentGoals: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD_DATA: {
            return {
                ...state,
                goalsCount: action.payload?.goalsCount,
                pollsCount: action.payload?.pollsCount,
                eventsCount: action.payload?.eventsCount,
                employeesCount: action.payload?.employeesCount,
                expensesCount: action.payload?.expensesCount,
                profileCompletion: action.payload?.profileCompletion,
                upcomingEvent: action.payload?.event?.upcomingList[0] || [],
                ongoingEvent: action.payload?.event?.ongoingList[0] || [],
                upcomingHolidays: action.payload?.upcomingHolidays?.list || [],
                expenses: action.payload?.expenses?.list || [],
                recentGoals: action.payload?.recentGoals || [],
            };
        }

        default:
            return state;
    }
}
