import { lazy, Suspense } from 'react';
import './style.css';
import {connect} from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    withRouter,
    Redirect,
} from 'react-router-dom';
import {appState, appDispatch} from './redux/appHelper';
import RouteWrapper from './utilities/RouteWrapper';
import ErrorHandler from './Containers/ErrorHandler/ErrorHandler';
import './screens/index.scss';
import './screens/Setup/components/CompanyInfo/CompanyInfo.scss';
import './screens/Dashboard/components/DashboardScreen.scss';
// employee
import './screens/Employees/EmployeeListing.scss';
import './screens/EmployeeDetails/EmployeeDetails.scss';
import './sharedComponents/EmployeesHeader/EmployeesHeader.scss';
import './screens/Employees/Employees.scss';
import './sharedComponents/EmployeeList/EmployeeList.scss';
import './screens/EmployeeDetails/components/EmployeeAssets/EmployeeAssets.scss';
// poll
import './screens/Polls/components/PollsListing/PollsListing.scss';
import './screens/Polls/components/PollDetails/PollDetails.scss';
import './screens/Polls/components/CreatePoll/CreatePoll.scss';
// emergency
import './screens/Emergency/components/EmergencyListing/EmergencyListing.scss';
// import './screens/Emergency/components/AddEmergency/AddEmergency.scss';
// announcements
// import './screens/Announcements/components/AnnouncementListing/AnnouncementListing.scss';
import './screens/Announcements/components/CreateAnnouncement/CreateAnnouncement.scss';
// import './screens/Announcements/components/AnnouncementCard/AnnouncementCard.scss';
// import './screens/EmployeeDetails/components/AssetCard/AssetCard.scss';
// assets
import './screens/Assets/components/AssetsListing/AssetsListing.scss';
import './screens/Assets/components/AssetDetails/AssetDetails.scss';
import './screens/Assets/components/CreateAsset/CreateAsset.scss';
// support
import './screens/Support/components/SupportDetails/SupportDetails.scss';
import './screens/Support/components/AddSupport/AddSupport.scss';
// feedback
import './screens/Feedback/FeedbackListing.scss';
// events
import './screens/Events/components/EventsListing/EventsListing.scss';
import './screens/Events/components/EventDetails/EventDetails.scss';
// import './screens/Events/components/EventCard/EventCard.scss';
// import './screens/Events/components/CreateEvent/CreateEvent.scss';
// policy
import './screens/Policies/PoliciesListing.scss';
import './screens/Policies/components/PolicyDetails/PolicyDetails.scss';
import './screens/Policies/components/AddPolicy/AddPolicy.scss';
// import './screens/Holidays/components/SetupHolidays/PoliciesListing.scss';
// import './screens/Setup/components/Policies/components/PolicyDetails/PolicyDetails.scss';
// setups
import './screens/Setup/components/Policies/components/AddPolicy/AddPolicy.scss';
// import './screens/Holidays/components/SetupHolidays/SetupHolidays.scss';
import './screens/Expenses/Component/ExpensesStyles.scss';

const Login = lazy(() => import('./components/Login/Login'));

const App = lazy(() => import('./Containers/AppContainer'));

const GoTag = (props) => (
    <Router>
        <ErrorHandler {...props}>
            <Switch>
                <RouteWrapper
                    path="/login/"
                    exact
                    render={() => (
                        <Suspense fallback={<div> </div>}>
                            <Login {...props} />
                        </Suspense>
                    )}
                />
                <RouteWrapper exact path="/">
                    <Redirect to="/login/" />
                </RouteWrapper>
                <RouteWrapper
                    path="/"
                    isPrivate
                    render={() => (
                        <Suspense fallback={<div> </div>}>
                            <App {...props} />
                        </Suspense>
                    )}
                />
            </Switch>
        </ErrorHandler>
    </Router>
);

const mapStateToProps = (state) => appState(state);

const mapDispatchToProps = (dispatch) => appDispatch(dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoTag));
