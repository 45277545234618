import jwtDecode from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteCookie, readTokenFromCookie, readCookie } from './cookie';

const RouteWrapper = ({ render: Render, isPrivate, ...rest }) => {
    const token = readTokenFromCookie();
    let signed = false;
    if (token) {
        const decoded = jwtDecode(token);
        if (!decoded || decoded.exp * 1000 < new Date().getTime()) {
            signed = false;
        } else {
            signed = true;
        }
    }

    if (isPrivate && !signed) {
        window.localStorage.clear();
        deleteCookie('token');
        deleteCookie('email');
        deleteCookie('adpAccessToken');
        deleteCookie('oauthType');
        return <Redirect to="/login/" />;
    }

    const { path } = rest;
    if (token && path === '/login/' && readCookie('oauthType') !== 'adp') {
        return <Redirect to="/home" />;
    }

    return <Route {...rest} render={Render} />;
};

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
};

export default RouteWrapper;
