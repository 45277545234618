import * as types from '../actions/types';

const initialState = {
    toastData: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_TOAST: {
            return {...state, toastData: action.payload};
        }

        default:
            return state;
    }
}
