import {CREATE_THUMBNAIL_LIST} from '../actions/types';

const initialState = {
    list: {},
    // data: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_THUMBNAIL_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        default:
            return state;
    }
}
