/**
 * @module index
 * @description  here reducer calls every child reducer, and gathers their results into a state objects.
 * @author Swati Shreya
 */

import {combineReducers} from 'redux';
import toastData from './toastReducers';
import employeesReducer from './employeesReducer';
import setupReducer from './setupReducers';
import policyReducer from './policyReducers';
import supportReducer from './supportReducers';
import emergencyReducer from './emergencyReducers';
import assetsReducer from './assetsReducers';
import pollsReducer from './pollsReducers';
import announcementsReducer from './announcementsReducers';
import feedbackReducer from './feedbackReducers';
import holidaysReducer from './holidaysReducers';
import expenseReducer from './expenseReducers';
import eventsReducer from './eventsReducer';
import notificationReducer from './notificationReducers';
import dashboardReducer from './dashboardReducer';
import goalReducer from './goalReducers';
import thumbnailReducer from './thumbnailReducers';

const rootReducer = combineReducers({
    toast: toastData,
    employees: employeesReducer,
    setup: setupReducer,
    policy: policyReducer,
    support: supportReducer,
    emergency: emergencyReducer,
    assets: assetsReducer,
    polls: pollsReducer,
    announcements: announcementsReducer,
    feedback: feedbackReducer,
    holidays: holidaysReducer,
    expenses: expenseReducer,
    events: eventsReducer,
    notifications: notificationReducer,
    dashboard: dashboardReducer,
    goals: goalReducer,
    thumbnail: thumbnailReducer,
});

export default rootReducer;
