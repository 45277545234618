import {UPDATE_TOAST} from './types';

/**
 * @function updateToastData
 * @description Function to show the toast message
 * @param {object} toastData the data to be shown in the toast message
 */
export function updateToastData(toastData) {
    return {
        type: UPDATE_TOAST,
        payload: toastData,
    };
}
