import {FEEDBACK_GET_LIST} from '../actions/types';

const initialState = {
    list: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FEEDBACK_GET_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        default:
            return state;
    }
}
