import { Component } from 'react';
import Axios from 'axios';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch() {
        // Display fallback UI
        this.setState({hasError: true});
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, info);
    }

    render() {
        Axios.interceptors.response.use(
            (response) =>
                // Do something with response data
                response,
            (error) =>
                // Do something with response error
                Promise.reject(error),
        );
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
