import {updateToastData} from './actions/toastActions';

export const appState = (state) => ({
    // TOAST DATA
    toastData: state.toast.toastData,
});

export const appDispatch = (dispatch) => ({
    // TOAST DATA
    updateToastData: (params) => dispatch(updateToastData(params)),
});
