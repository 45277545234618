import {
    POLLS_GET_ALL_LIST,
    POLLS_GET_EXPIRED_LIST,
    POLLS_GET_UPCOMING_LIST,
    POLLS_GET_ACTIVE_LIST,
    POLLS_DELETE_ID,
    POLLS_ADD_NEW,
    POLLS_UPDATE_ID,
    POLLS_ERROR,
    POLLS_ALL_SHOW_MORE,
    POLLS_EXPIRED_SHOW_MORE,
    POLLS_UPCOMING_SHOW_MORE,
    POLLS_ACTIVE_SHOW_MORE,
} from '../actions/types';

const initialState = {
    allList: [],
    expiredList: [],
    upcomingList: [],
    activeList: [],
    pollsError: false,
    showMoreAllCount: 0,
    showMoreExpiredCount: 0,
    showMoreActiveCount: 0,
    showMoreUpcomingCount: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case POLLS_GET_ALL_LIST: {
            return {
                ...state,
                allList: action.payload,
            };
        }

        case POLLS_GET_EXPIRED_LIST: {
            return {
                ...state,
                expiredList: action.payload,
            };
        }

        case POLLS_GET_UPCOMING_LIST: {
            return {
                ...state,
                upcomingList: action.payload,
            };
        }

        case POLLS_GET_ACTIVE_LIST: {
            return {
                ...state,
                activeList: action.payload,
            };
        }

        case POLLS_DELETE_ID: {
            const deleteAllPolls = state.allList.filter(
                (poll) => poll._id !== action.payload._id,
            );
            let isActive = false;
            const deleteActivePolls = state.activeList.filter((poll) => {
                if (poll._id !== action.payload._id) {
                    return true;
                }
                isActive = true;
                return false;
            });

            const deleteUpcomingPolls = state.upcomingList.filter(
                (poll) => poll._id !== action.payload._id,
            );

            return {
                ...state,
                allList: deleteAllPolls,
                activeList: deleteActivePolls,
                upcomingList: deleteUpcomingPolls,
                showMoreActiveCount: isActive
                    ? state.showMoreActiveCount - 1
                    : state.showMoreActiveCount,
                showMoreUpcomingCount: isActive
                    ? state.showMoreUpcomingCount
                    : state.showMoreUpcomingCount - 1,
                showMoreAllCount: state.showMoreAllCount - 1,
            };
        }

        case POLLS_ADD_NEW: {
            const addNewAllPolls = state.allList;
            const addNewActivePolls = state.activeList;
            const addNewUpcomingPolls = state.upcomingList;
            if (new Date() < new Date(action.payload.created_date)) {
                addNewUpcomingPolls.push(action.payload);
            } else {
                addNewActivePolls.push(action.payload);
            }
            addNewAllPolls.push(action.payload);
            return {
                ...state,
                allList: addNewAllPolls,
                activeList: addNewActivePolls,
                expiredList: addNewUpcomingPolls,
            };
        }

        case POLLS_UPDATE_ID: {
            const updatedAllPolls = state.allList.map((allPolls) => {
                if (allPolls._id === action.payload._id) {
                    return action.payload;
                }
                return allPolls;
            });
            const updatedActivePolls = state.activeList.map((activePolls) => {
                if (activePolls._id === action.payload._id) {
                    return action.payload;
                }
                return activePolls;
            });
            const updatedExpiredPolls = state.expiredList.map(
                (expiredPolls) => {
                    if (expiredPolls._id === action.payload._id) {
                        return action.payload;
                    }
                    return expiredPolls;
                },
            );
            const updatedUpcomingPolls = state.upcomingList.map(
                (upcomingPolls) => {
                    if (upcomingPolls._id === action.payload._id) {
                        return action.payload;
                    }
                    return upcomingPolls;
                },
            );
            return {
                ...state,
                allList: updatedAllPolls,
                activeList: updatedActivePolls,
                expiredList: updatedExpiredPolls,
                upcomingList: updatedUpcomingPolls,
            };
        }

        case POLLS_ERROR: {
            return {
                ...state,
                pollsError: action.payload,
            };
        }

        case POLLS_ALL_SHOW_MORE: {
            return {
                ...state,
                showMoreAllCount: action.payload,
            };
        }

        case POLLS_EXPIRED_SHOW_MORE: {
            return {
                ...state,
                showMoreExpiredCount: action.payload,
            };
        }

        case POLLS_UPCOMING_SHOW_MORE: {
            return {
                ...state,
                showMoreUpcomingCount: action.payload,
            };
        }

        case POLLS_ACTIVE_SHOW_MORE: {
            return {
                ...state,
                showMoreActiveCount: action.payload,
            };
        }

        default:
            return state;
    }
}
