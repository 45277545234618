import {
    SUPPORT_GET_LIST,
    SUPPORT_DELETE_ID,
    SUPPORT_ADD_NEW,
    SUPPORT_UPDATE_ID,
} from '../actions/types';

const initialState = {
    list: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SUPPORT_GET_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case SUPPORT_DELETE_ID: {
            const updatedSupportList = state.list.map((support) => {
                const deleteSupport = {...support};
                delete deleteSupport._id;
                return deleteSupport;
            });
            return {...state, list: updatedSupportList};
        }

        case SUPPORT_ADD_NEW: {
            const addNewSupportList = state.list;
            addNewSupportList.push(action.payload);
            return {...state, list: addNewSupportList};
        }

        case SUPPORT_UPDATE_ID: {
            const updatedSupportList = state.list.map((support) => {
                if (support._id === action.payload._id) {
                    return action.payload;
                }
                return support;
            });
            return {...state, list: updatedSupportList};
        }

        default:
            return state;
    }
}
