import {
    EMERGENCY_GET_LIST,
    EMERGENCY_DELETE_ID,
    EMERGENCY_ADD_NEW,
    EMERGENCY_UPDATE_ID,
} from '../actions/types';

const initialState = {
    list: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EMERGENCY_GET_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case EMERGENCY_DELETE_ID: {
            const updatedEmergencyList = state.list.map((emergency) => {
                const deleteEmergency = {...emergency};
                delete deleteEmergency._id;
                return deleteEmergency;
            });
            return {...state, list: updatedEmergencyList};
        }

        case EMERGENCY_ADD_NEW: {
            const addNewEmergencyList = state.list;
            addNewEmergencyList.push(action.payload);
            return {...state, list: addNewEmergencyList};
        }

        case EMERGENCY_UPDATE_ID: {
            const updatedEmergencyList = state.list.map((emergency) => {
                if (emergency._id === action.payload._id) {
                    return action.payload;
                }
                return emergency;
            });
            return {...state, list: updatedEmergencyList};
        }

        default:
            return state;
    }
}
