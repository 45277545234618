import {
    ANNOUNCEMENTS_GET_LIST,
    ANNOUNCEMENTS_DELETE_ID,
    ANNOUNCEMENTS_ADD_NEW,
    ANNOUNCEMENTS_UPDATE_ID,
    ANNOUNCEMENTS_SHOW_MORE,
} from '../actions/types';

const initialState = {
    list: [],
    showMoreCount: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ANNOUNCEMENTS_GET_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case ANNOUNCEMENTS_DELETE_ID: {
            const deleteAnnouncements = state.list;
            let index;
            // eslint-disable-next-line array-callback-return
            deleteAnnouncements.map((item) => {
                if (item._id === action.payload._id) {
                    index = deleteAnnouncements.indexOf(item);
                }
            });
            deleteAnnouncements.splice(index, 1);

            return {
                ...state,
                list: deleteAnnouncements,
                showMoreCount: state.showMoreCount - 1,
            };
        }

        case ANNOUNCEMENTS_ADD_NEW: {
            const addNewAnnouncmentList = state.list;
            addNewAnnouncmentList.push(action.payload);
            return {
                ...state,
                list: addNewAnnouncmentList,
                showMoreCount: state.showMoreCount + 1,
            };
        }

        case ANNOUNCEMENTS_UPDATE_ID: {
            const updatedAnnouncementsList = state.list.map((announcements) => {
                if (announcements._id === action.payload._id) {
                    return action.payload;
                }
                return announcements;
            });
            return { ...state, list: updatedAnnouncementsList };
        }
        case ANNOUNCEMENTS_SHOW_MORE: {
            return {
                ...state,
                showMoreCount: action.payload,
            };
        }

        default:
            return state;
    }
}
