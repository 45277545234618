import formatDateTime from 'piktor-format/core/formatDateTime';
import {
    HOLIDAYS_GET_LIST,
    ALL_HOLIDAYS_SET_LIST,
    UPDATE_SELECTED_HOLIDAYS_LIST,
} from '../actions/types';

const initialState = {
    holidayList: [],
    allHolidaysList: [],
    selectedHolidaysList: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HOLIDAYS_GET_LIST: {
            return {
                ...state,
                holidayList: action.payload,
            };
        }
        case ALL_HOLIDAYS_SET_LIST: {
            const days = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];
            const daysShort = [
                'Sun',
                'Mon',
                'Tue',
                'Wed',
                'Thur',
                'Fri',
                'Sat',
            ];
            const countryPublic = action.params.country;
            // const setCountryData = (countryObject) => {
            //     if (countryObject === 'US') {
            //         return 'USA';
            //     } else if (countryObject === '') return 'India';
            // };
            const getCountryName = (value) => {
                switch (value) {
                    case 'IN':
                        return 'India';
                    case 'US':
                        return 'USA';
                    case 'CA':
                        return 'Canada';
                    default:
                        return value;
                }
            };
            const {holidayList} = state;
            let holidaysForSelectedLocation = holidayList.filter(
                (item) => item.country === getCountryName(countryPublic),
            );
            const fullHolidayData = action.payload;
            const indexesToshow = [];
            const namesArray = [];
            const addedHolidaysNames = [];
            const nonDuplicateNames = [];
            holidaysForSelectedLocation.map((item) =>
                addedHolidaysNames.push(item.occasion),
            );
            holidaysForSelectedLocation.sort(
                (a, b) =>
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    new Date(b.updatedAt) - new Date(a.updatedAt),
            );
            fullHolidayData.map((item) => namesArray.push(item.start.date));
            namesArray.filter((item, index) => {
                if (namesArray.indexOf(item) === index) {
                    indexesToshow.push(index);
                }
                return namesArray.indexOf(item) === index;
            });
            // setIndexes(indexesToshow);
            let nonDuplicateArray = [];
            // eslint-disable-next-line array-callback-return
            fullHolidayData.map((item, index) => {
                if (indexesToshow.includes(index)) {
                    nonDuplicateArray.push(item);
                }
            });
            nonDuplicateArray.map((item) =>
                nonDuplicateNames.push(item.summary),
            );
            // setPreSelectedHolidays(nonDuplicateNames, addedHolidaysNames);
            let count = 0;

            holidaysForSelectedLocation = holidaysForSelectedLocation.filter(
                (item) =>
                    !nonDuplicateNames.includes(item.occasion) && item.type === 'floating',
            );

            const allHolidaysArray = holidaysForSelectedLocation.map(
                (item, index) => {
                    const dayNumber = new Date(item.date).getDay();
                    count += 1;
                    const obj_ = {
                        name: item.occasion.toLowerCase(),
                        date: daysShort[dayNumber]
                            .concat(', ')
                            .concat(
                                formatDateTime(
                                    item.date,
                                    'monthFirst',
                                    'candidate',
                                ),
                            ),
                        typeObject: {
                            type: item.type,
                            name: item.occasion,
                            date: item.date,
                        },

                        day: days[dayNumber],
                        sNo: count,
                        country: item.country,
                        checkBoxObject: {
                            name: item.occasion,
                            date: formatDateTime(
                                item.date,
                                'monthFirst',
                                'candidate',
                            ),
                            bluetick: false,
                            type: item.type,
                            day: days[dayNumber],
                            key: index,
                            country: item.country,
                        },
                    };
                    return obj_;
                },
            );

            nonDuplicateArray = nonDuplicateArray.map((item, index) => {
                const dayNumber = new Date(item.start.date).getDay();
                count += 1;
                const obj = {
                    name: item.summary.toLowerCase(),
                    date: daysShort[dayNumber]
                        .concat(', ')
                        .concat(
                            formatDateTime(
                                item.start.date,
                                'monthFirst',
                                'candidate',
                            ),
                        ),
                    typeObject: {
                        type: item.visibility,
                        name: item.summary,
                        date: item.start.date,
                    },
                    day: days[dayNumber],
                    sNo: count,
                    country: countryPublic,
                    checkBoxObject: {
                        name: item.summary,
                        date: daysShort[dayNumber]
                            .concat(', ')
                            .concat(
                                formatDateTime(
                                    item.start.date,
                                    'monthFirst',
                                    'candidate',
                                ),
                            ),
                        type: item.visibility,
                        //  bluetick: false,
                        day: days[dayNumber],
                        key: index,
                        country: countryPublic,
                    },
                };
                return obj;
            });

            let temp = [];
            temp = [...allHolidaysArray, ...nonDuplicateArray];
            temp.sort((a, b) => new Date(a.date) - new Date(b.date));
            return {
                ...state,
                allHolidaysList: temp,
            };
        }
        case UPDATE_SELECTED_HOLIDAYS_LIST: {
            return {
                ...state,
                selectedHolidaysList: action.payload,
            };
        }

        default:
            return state;
    }
}
