import {
    UPDATE_USER_DATA,
    UPDATE_ACCESS_STATUS,
    UPDATE_SETUP_INDEX,
    UPDATE_SETUP_TAB,
    UPDATE_SETUP_DONE,
    UPDATE_SETUP_DATA,
    CLEAR_SETUP_DATA,
} from '../actions/types';

const initialState = {
    currUserData: {
        localization: {
            formatDateTime: () => {},
        },
    },
    setupTabValues: [],
    accessStatus: {},
    setupData: {
        companyInfo: {},
        employees: [],
        holidays: [],
        policies: [],
        support: [],
        emergency: [],
        googleApiData: [],
        adpEmpList: [],
        managers: [],
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_DATA: {
            return {
                ...state,
                currUserData: action.payload,
            };
        }
        case UPDATE_ACCESS_STATUS: {
            return {
                ...state,
                accessStatus: action.payload,
            };
        }
        case UPDATE_SETUP_DONE: {
            return {
                ...state,
                isSetup: action.payload,
            };
        }
        case UPDATE_SETUP_INDEX: {
            return {
                ...state,
                setupTabIndex: action.payload,
            };
        }
        case UPDATE_SETUP_TAB: {
            return {
                ...state,
                setupTabValues: action.payload,
            };
        }
        case UPDATE_SETUP_DATA: {
            const [key, value] = action.payload;
            const setData = state.setupData;
            setData[key] = value;
            return {
                ...state,
                setupData: setData,
            };
        }
        case CLEAR_SETUP_DATA: {
            const setData = {
                companyInfo: {},
                employees: [],
                holidays: [],
                policies: [],
                support: [],
                emergency: [],
                googleApiData: [],
                adpEmpList: [],
                managers: action.payload || [],
            };
            return {
                ...state,
                setupData: setData,
            };
        }
        default:
            return state;
    }
}
