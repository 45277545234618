import {
    CREATE_EVENT,
    EDIT_EVENT,
    SET_EVENTS_LIST,
    SET_ALL_LIST,
    SET_ONGOING_LIST,
    SET_UPCOMING_LIST,
    SET_ALL_COUNT,
    SET_ONGOING_COUNT,
    SET_UPCOMING_COUNT,
    SET_ALL_PAGE_COUNT,
    SET_ONGOING_PAGE_COUNT,
    SET_UPCOMING_PAGE_COUNT,
    SET_SORT_DETAILS,
    SET_SORT_LIST,
    DELETE_EVENT,
} from '../actions/types';

const initialState = {
    allList: [],
    ongoingList: [],
    upcomingList: [],
    sortList: [],
    allCount: 0,
    ongoingCount: 0,
    upcomingCount: 0,
    allPageCount: 0,
    ongoingPageCount: 0,
    upcomingPageCount: 0,
    list: [],
    sortDetails: {
        tag: '',
        isSorted: false,
        sortField: '',
        page: 0,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_EVENT: {
            // const updatedList = [action.payload, ...state.allList];
            const updatedUpcomingList = [action.payload, ...state.upcomingList];

            return {
                ...state,
                // allList: updatedList,
                upcomingList: updatedUpcomingList,
                // allCount: state.allCount + 1,
                upcomingCount: state.upcomingCount + 1,
            };
        }

        case EDIT_EVENT: {
            // const updatedList = state.allList.map((event) => {
            //     if (event._id === action.payload._id) {
            //         return {...event, ...action.payload};
            //     }
            //     return event;
            // });
            const updatedUpcomingList = state.upcomingList.map((event) => {
                if (event._id === action.payload._id) {
                    return {...event, ...action.payload};
                }
                return event;
            });
            return {
                ...state,
                // allList: updatedList,
                upcomingList: updatedUpcomingList,
            };
        }

        case DELETE_EVENT: {
            let updatedCount = state.allCount;
            let updatedUpcomingCount = state.upcomingCount;
            let updatedOngoingCount = state.ongoingCount;

            const updatedList = state.allList.filter(
                (event) => event._id !== action.payload,
            );

            const updatedUpcomingList = state.upcomingList.filter(
                (event) => event._id !== action.payload,
            );

            const updatedOngoingList = state.ongoingList.filter(
                (event) => event._id !== action.payload,
            );

            if (updatedList.length !== state.allList.length) {
                updatedCount -= 1;
            }
            if (updatedUpcomingList.length !== state.upcomingList.length) {
                updatedUpcomingCount -= 1;
            }
            if (updatedOngoingList.length !== state.ongoingList.length) {
                updatedOngoingCount -= 1;
            }
            return {
                ...state,
                allList: updatedList,
                upcomingList: updatedUpcomingList,
                ongoingList: updatedOngoingList,
                allCount: updatedCount,
                upcomingCount: updatedUpcomingCount,
                ongoingCount: updatedOngoingCount,
            };
        }

        case SET_EVENTS_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case SET_ALL_LIST: {
            return {
                ...state,
                allList: action.payload,
            };
        }

        case SET_ALL_COUNT: {
            return {
                ...state,
                allCount: action.payload,
            };
        }

        case SET_ALL_PAGE_COUNT: {
            return {
                ...state,
                allPageCount: action.payload,
            };
        }

        case SET_UPCOMING_LIST: {
            return {
                ...state,
                upcomingList: action.payload,
            };
        }

        case SET_UPCOMING_COUNT: {
            return {
                ...state,
                upcomingCount: action.payload,
            };
        }

        case SET_UPCOMING_PAGE_COUNT: {
            return {
                ...state,
                upcomingPageCount: action.payload,
            };
        }

        case SET_ONGOING_LIST: {
            return {
                ...state,
                ongoingList: action.payload,
            };
        }

        case SET_ONGOING_COUNT: {
            return {
                ...state,
                ongoingCount: action.payload,
            };
        }

        case SET_ONGOING_PAGE_COUNT: {
            return {
                ...state,
                ongoingPageCount: action.payload,
            };
        }

        case SET_SORT_LIST: {
            return {
                ...state,
                sortList: action.payload,
            };
        }

        case SET_SORT_DETAILS: {
            return {
                ...state,
                sortDetails: action.payload,
            };
        }

        default:
            return state;
    }
}
