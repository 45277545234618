import {
    MODIFY_EXPENSE,
    GET_EXPENSES_LIST,
    GET_FILTERED_LIST,
    GET_FILTERED_MONTH,
    GET_FILTERED_STATUS,
} from '../actions/types';

const initialState = {
    list: [],
    filteredList: [],
    loading: true,
    filterMonth: 'None',
    filterStatus: 'None',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_EXPENSES_LIST: {
            return {
                ...state,
                list: action.payload,
                loading: false,
            };
        }

        case GET_FILTERED_LIST: {
            return {
                ...state,
                filteredList: action.payload,
                loading: false,
            };
        }

        case GET_FILTERED_MONTH: {
            return {
                ...state,
                filterMonth: action.payload,
            };
        }

        case GET_FILTERED_STATUS: {
            return {
                ...state,
                filterStatus: action.payload,
            };
        }

        case MODIFY_EXPENSE: {
            const newExpensesList = state.list.map((expense) => {
                if (expense._id === action.payload._id) {
                    return {...expense, ...action.payload};
                }
                return expense;
            });
            const newFilteredExpensesList = state.filteredList.map(
                (expense) => {
                    if (expense._id === action.payload._id) {
                        return {...expense, ...action.payload};
                    }
                    return expense;
                },
            );
            return {
                ...state,
                list: newExpensesList,
                filteredList: newFilteredExpensesList,
                loading: false,
            };
        }

        default:
            return state;
    }
}
