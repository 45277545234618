import jwtDecode from 'jwt-decode';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import {store} from '../redux/store';
import {updateToastData} from '../redux/actions/toastActions';

/**
 * @function readCookie
 * @description Function to read any cookie value by its name
 */
export const readCookie = (cname) => {
    const cookieName = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookieArray.length; i++) {
        const cookieItem = cookieArray[i].trim();
        if (cookieItem.indexOf(cookieName) === 0) {
            const cookieValue = cookieItem.substring(
                cookieName.length,
                cookieItem.length,
            );
            return cookieValue;
        }
    }
};

/**
 * @function storeTokenInCookie
 * @description Function to store token in cookies in encrypted form
 */
export const storeTokenInCookie = (jwtToken) => {
    if (!jwtToken) {
        return;
    }
    const decoded = jwtDecode(jwtToken);
    const encryptedToken = encryptUserToken(jwtToken);
    if (decoded) {
        const expiryTime = new Date(decoded.exp * 1000).toUTCString();
        document.cookie = `token=${encodeURIComponent(
            encryptedToken,
        )};expires=${expiryTime};path=/;`;
    }
};

/**
 * @function readTokenFromCookie
 * @description Function to read token from cookie after decrypting
 */
export const readTokenFromCookie = () => decryptUserToken(readCookie('token'));

/**
 * @function setCookie
 * @description Function to store any cookie with expiry equal to token expiry
 */
export const setCookie = (cname, cvalue) => {
    const token = readTokenFromCookie();
    if (!token) {
        return;
    }
    const decoded = jwtDecode(token);
    if (decoded) {
        const expiryTime = new Date(decoded.exp * 1000).toUTCString();
        document.cookie = `${cname}=${encodeURIComponent(
            cvalue,
        )};expires=${expiryTime};path=/;`;
    }
};

/**
 * @function deleteCookie
 * @description Function to delete any cookie by its name
 */
export const deleteCookie = (cname) => {
    const d = new Date();
    d.setTime(d.getTime() - 12 * 60 * 60 * 1000);
    document.cookie = `${cname}=;expires=${d.toUTCString()};path=/;`;
};

/**
 * @function encryptUserToken
 * @description Function to encrpyt user token
 */
const encryptUserToken = (token) => {
    const privateKey = 'bCsm3DCYfU8b2Hd5cqHe4XhtC1EuxmsSqyXjxvXhug7wFH3f7z';
    const cipher = aes.encrypt(token, privateKey).toString();
    return cipher;
};

/**
 * @function decryptUserToken
 * @description Function to decrypt user token
 */
export const decryptUserToken = (encryptedToken) => {
    const privateKey = 'bCsm3DCYfU8b2Hd5cqHe4XhtC1EuxmsSqyXjxvXhug7wFH3f7z';
    if (!encryptedToken) {
        return;
    }
    const bytes = aes.decrypt(encryptedToken, privateKey);
    if (bytes.sigBytes > 0) {
        try {
            const decryptedToken = bytes.toString(enc);
            return decryptedToken;
        } catch (e) {
            return null;
        }
    }
};

/**
 * @function storeADPAccessTokenInCookie
 * @description Function to store ADP token in cookies in encrypted form
 */
export const storeADPAccessTokenInCookie = (adpAccessToken) => {
    if (!adpAccessToken) {
        return;
    }
    const currentTime = new Date();
    const after58MinsString = new Date(
        currentTime.setMinutes(currentTime.getMinutes() + 58),
    ).toISOString();
    const obj = {
        token: adpAccessToken,
        expiry: after58MinsString,
    };
    const encryptedToken = encryptUserToken(JSON.stringify(obj));
    const expiryTime = new Date(after58MinsString).toUTCString();
    document.cookie = `adpAccessToken=${encodeURIComponent(
        encryptedToken,
    )};expires=${expiryTime};path=/;`;
};

/**
 * @function readADPToken
 * @description Function to read ADP token from cookies after decrypting
 */
export const readADPToken = () => {
    const decryptedToken = decryptUserToken(readCookie('adpAccessToken'));
    if (decryptedToken) {
        return JSON.parse(decryptedToken);
    }
    return {token: ''};
};

/**
 * @function clearSession
 * @description Function to clear session by deleting data from cookie and localStorage, and redirect to login page by default
 */
export const clearSession = async (dontRouteToLogin = false) => {
    window.localStorage.clear();
    deleteCookie('email');
    deleteCookie('token');
    deleteCookie('adpAccessToken');
    deleteCookie('oauthType');
    const arrayOfCacheKeys = await caches.keys();
    arrayOfCacheKeys.forEach((cache) => {
        caches.delete(cache);
    });
    if (!dontRouteToLogin) {
        window.location.href = `${window.location.origin}/login/`;
    }
};

/**
 * @function clearSession
 * @description Function to clear session by deleting data from cookie and localStorage, and redirect to login page by default
 */
export const clearSessionTokenExpiry = async () => {
    window.localStorage.clear();
    deleteCookie('email');
    deleteCookie('token');
    deleteCookie('adpAccessToken');
    deleteCookie('oauthType');
    const arrayOfCacheKeys = await caches.keys();
    arrayOfCacheKeys.forEach((cache) => {
        caches.delete(cache);
    });
    // window.location.href = `${window.location.origin}/login/`;
    store.dispatch(
        updateToastData({
            message: 'Session Expired, Relogin to continue',
            error: true,
            permanent: true,
        }),
    );
};

/**
 * @function clearSession
 * @description Function to clear session by deleting data from cookie and localStorage, and redirect to login page by default
 */
export const clearSessionTokenExpiryVarMessage = async (message) => {
    window.localStorage.clear();
    deleteCookie('email');
    deleteCookie('token');
    deleteCookie('adpAccessToken');
    deleteCookie('oauthType');
    const arrayOfCacheKeys = await caches.keys();
    arrayOfCacheKeys.forEach((cache) => {
        caches.delete(cache);
    });
    // window.location.href = `${window.location.origin}/login/`;
    store.dispatch(
        updateToastData({
            message,
            error: true,
            permanent: true,
        }),
    );
};
