import {
    POLICY_GET_LIST,
    POLICY_DELETE_ID,
    POLICY_ADD_NEW,
    POLICY_UPDATE_ID,
} from '../actions/types';

const initialState = {
    list: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case POLICY_GET_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }

        case POLICY_DELETE_ID: {
            const updatedPolicyList = state.list.map((policy) => {
                const deletePolicy = {...policy};
                delete deletePolicy._id;
                return deletePolicy;
            });
            return {...state, list: updatedPolicyList};
        }

        case POLICY_ADD_NEW: {
            const addNewPolicyList = state.list;
            addNewPolicyList.push(action.payload);
            return {...state, list: addNewPolicyList};
        }

        case POLICY_UPDATE_ID: {
            const updatedPolicyList = state.list.map((policy) => {
                if (policy._id === action.payload._id) {
                    return action.payload;
                }
                return policy;
            });
            return {...state, list: updatedPolicyList};
        }

        default:
            return state;
    }
}
