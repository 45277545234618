import {
    ASSETS_GET_ASSIGNED_LIST,
    ASSETS_GET_UNASSIGNED_LIST,
    ASSETS_GET_SORT_DETAILS,
    ASSETS_GET_SORT_LIST,
    ASSETS_GET_ASSIGNED_COUNT,
    ASSETS_GET_UNASSIGNED_COUNT,
    ASSETS_DELETE_ID,
    ASSETS_ADD_NEW,
    ASSETS_UPDATE_ID,
    ASSETS_ADD_MULTIPLE,
    ASSETS_ID_RETURN,
    ASSETS_GET_UNASSIGNED_PAGE_COUNT,
} from '../actions/types';

const initialState = {
    assignedList: [],
    unassignedList: [],
    showMoreAssignCount: 0,
    showMoreUnassignCount: 0,
    unassignPageCount: 0,
    sortedList: [],
    sortDetails: {
        tag: '',
        isSorted: false,
        isSortedDesc: false,
        sortField: '',
        page: 0,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ASSETS_GET_ASSIGNED_LIST: {
            return {
                ...state,
                assignedList: action.payload,
            };
        }

        case ASSETS_GET_UNASSIGNED_LIST: {
            return {
                ...state,
                unassignedList: action.payload,
            };
        }

        case ASSETS_GET_SORT_LIST: {
            return {
                ...state,
                sortedList: action.payload,
            };
        }

        case ASSETS_GET_UNASSIGNED_PAGE_COUNT: {
            return {
                ...state,
                unassignPageCount: action.payload,
            };
        }

        case ASSETS_GET_SORT_DETAILS: {
            return {
                ...state,
                sortDetails: action.payload,
            };
        }

        case ASSETS_GET_ASSIGNED_COUNT: {
            return {
                ...state,
                showMoreAssignCount: action.payload,
            };
        }

        case ASSETS_GET_UNASSIGNED_COUNT: {
            return {
                ...state,
                showMoreUnassignCount: action.payload,
            };
        }

        case ASSETS_DELETE_ID: {
            const updatedAssignedList = state.assignedList;
            const updatedUnassignedList = state.unassignedList;
            let updatedAssignedCount = state.showMoreAssignCount;
            let updatedUnassignedCount = state.showMoreUnassignCount;

            let index;
            if (action.payload.assigned) {
                // eslint-disable-next-line array-callback-return
                updatedAssignedList.map((assets) => {
                    if (assets._id === action.payload._id) {
                        index = updatedAssignedList.indexOf(assets);
                    }
                });
                updatedAssignedList.splice(index, 1);
                updatedAssignedCount -= 1;
            } else {
                // eslint-disable-next-line array-callback-return
                updatedUnassignedList.map((assets) => {
                    if (assets._id === action.payload._id) {
                        index = updatedUnassignedList.indexOf(assets);
                    }
                });
                updatedUnassignedList.splice(index, 1);
                updatedUnassignedCount -= 1;
            }
            return {
                ...state,
                showMoreAssignCount: updatedAssignedCount,
                showMoreUnassignCount: updatedUnassignedCount,
                assignedList: updatedAssignedList,
                unassignedList: updatedUnassignedList,
            };
        }

        case ASSETS_ADD_NEW: {
            const addNewAssetsList = state.unassignedList;
            let updatedUnassignedCount = state.showMoreUnassignCount;
            addNewAssetsList.push(action.payload);
            updatedUnassignedCount += 1;
            return {
                ...state,
                showMoreUnassignCount: updatedUnassignedCount,
                unassignedList: addNewAssetsList,
            };
        }

        case ASSETS_UPDATE_ID: {
            let updatedUnassignedList;
            let updatedAssignedList;
            let updatedAssignedCount = state.showMoreAssignCount;
            let updatedUnassignedCount = state.showMoreUnassignCount;
            if (!action.payload.assigned) {
                updatedUnassignedList = state.unassignedList.map((assets) => {
                    if (assets._id === action.payload._id) {
                        return action.payload;
                    }
                    return assets;
                });
                updatedAssignedList = state.assignedList;
            } else {
                let index;
                updatedUnassignedList = state.unassignedList;
                // eslint-disable-next-line array-callback-return
                updatedUnassignedList.map((assets) => {
                    if (assets._id === action.payload._id) {
                        index = updatedUnassignedList.indexOf(assets);
                    }
                });
                updatedUnassignedList.splice(index, 1);
                updatedUnassignedCount -= 1;
                let updatedFlag = false;
                updatedAssignedList = state.assignedList.map((assets) => {
                    if (assets._id === action.payload._id) {
                        updatedFlag = true;
                        return action.payload;
                    }
                    return assets;
                });
                if (!updatedFlag) {
                    updatedAssignedList = state.assignedList;
                    updatedAssignedList.push(action.payload);
                    updatedAssignedCount += 1;
                }
            }

            return {
                ...state,
                showMoreAssignCount: updatedAssignedCount,
                showMoreUnassignCount: updatedUnassignedCount,
                assignedList: updatedAssignedList,
                unassignedList: updatedUnassignedList,
            };
        }

        case ASSETS_ADD_MULTIPLE: {
            const addManyAssetsList = state.unassignedList;
            let updatedUnassignedCount = state.showMoreUnassignCount;
            action.payload.map((assets) => {
                addManyAssetsList.push(assets);
                return assets;
            });
            updatedUnassignedCount += action.payload.length;
            return {
                ...state,
                showMoreUnassignCount: updatedUnassignedCount,
                unassignedList: addManyAssetsList,
            };
        }

        case ASSETS_ID_RETURN: {
            let index;
            const updatedAssignedList = state.assignedList;
            let updatedAssignedCount = state.showMoreAssignCount;
            let updatedUnassignedCount = state.showMoreUnassignCount;
            // eslint-disable-next-line array-callback-return
            updatedAssignedList.map((assets) => {
                if (assets._id === action.payload._id) {
                    index = updatedAssignedList.indexOf(assets);
                }
            });
            updatedAssignedList.splice(index, 1);
            updatedAssignedCount -= 1;
            const updatedUnassignedList = state.unassignedList;
            updatedUnassignedList.push(action.payload);
            updatedUnassignedCount += 1;
            return {
                ...state,
                showMoreAssignCount: updatedAssignedCount,
                showMoreUnassignCount: updatedUnassignedCount,
                assignedList: updatedAssignedList,
                unassignedList: updatedUnassignedList,
            };
        }

        default:
            return state;
    }
}
